import InvestorLayout from "../utils/InvestorLayout"

const Cirp = ({landingPageData}) => {
    return (
        <div>
            <InvestorLayout title="Policies & Certificates" links={landingPageData.Cirp}/>
        </div>
    )
}

export default Cirp
