import {Link} from "react-router-dom";

const InvestorRelations = ({landingPageData}) => {
  return (
    <div id='investorrelations'>
        <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='container'>
          <div className='section-title text-center'>
            <h1>Investor Relations</h1>
          </div>
          <div className='row'>
                <div className='col-md-4'>
                    <ul>
                      <li>
                        <Link to="/investorRelations/corporate-information">Corporate Information</Link>
                      </li>
                      <li>
                          <Link to="/investorRelations/financial-results">Financial Results</Link>
                      </li>
                      <li>
                          <Link to="/investorRelations/shareholding-pattern">Shareholding Pattern</Link>
                      </li>
                      <li>
                        <Link to="/investorRelations/board-of-directors">Composition of committees of Board of directors</Link>
                      </li>
                      <li>
                        <Link to="official-details-investor-grievances">Details of Official: Investor Grievances</Link>
                      </li>
                    </ul>
                </div>
                <div className='col-md-4'>
                    <ul>
                      <li>
                          <Link to="/investorRelations/annual-report">Annual Report</Link>
                      </li>
                      <li>
                          <Link to="/investorRelations/board-meetings">Board Meetings</Link>
                      </li>
                      <li>
                          <Link to="/investorRelations/agm">Annual/ Extra-ordinary General Meeting </Link>
                      </li>
                      <li>
                        <Link to="/investorRelations/annual-return">Annual Return</Link>
                      </li>
                      <li>
                        <Link to="/investorRelations/kmp-authorised">KMP Authorised to Determine Materiality of Event</Link>
                      </li>
                    </ul>
                </div>
                <div className='col-md-4'>
                    <ul>
                        <li>
                            <Link to="/investorRelations/policies">Policies & Certificates</Link>
                        </li>
                        <li>
                            <Link to="/investorRelations/stock-exchange-intimition">Stock Exchange Intimition</Link>
                        </li>
                        <li>
                            <Link to="/investorRelations/investor-grievances-cell">Investor Grievances Cell</Link>
                        </li>
                        <li>
                            <Link to="/investorRelations/ipo">IPO</Link>
                        </li>
                        <li>
                            <Link to="/investorRelations/investor-presentation">Investor Presentation</Link>
                        </li>
                        <li>
                            <Link to="/investorRelations/cirp">Corporate Insolvency Resolution Process</Link>
                        </li>
                    </ul>
                </div>

          </div>

        </div>

        </div>

    </div>
  )
}

export default InvestorRelations
